import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import logo130 from "../../../images/quilmesMusica/svg/quilmes-web-01.svg"
import cubes from "../../../images/quilmesMusica/svg/cubes.svg"
import backArrow from "../../../images/quilmesMusica/svg/quilmes-web-18.svg"
import styles from "../../../styles/headerMapMusic.module.scss"
import * as Scroll from "react-scroll"

let LinkTo = Scroll.Link

const HeaderProvinciaMap = ({ title, text }) => {
  //   const [activeMenu, setActiveMenu] = useState(true)
  return (
    <header className={styles.main}>
      <div>
        <Link to={`/quilmesfestivales`}>
          <img className={styles.logo} src={logo130} alt="logo130" />
        </Link>
      </div>

      <div className={styles.midContainer}>
        <h1>{title}</h1>
        {/* <h2 className={styles.secondTextMobile}>{text}</h2> */}
        <h2 className={styles.secondText}>{text}</h2>
      </div>

      <div className={styles.buttonsContainer}>
        <div
          className={`${styles.menuButton}`}
          onClick={() => navigate(`/quilmesfestivales#mapaquilmes`)}
        >
          <div className={styles.backButton}>
            <img src={backArrow} alt="backArrow" />
            <p> VOLVER</p>
          </div>
        </div>

        <div
          className={styles.menuButton}
          onClick={() => {
            navigate(`/quilmesfestivales/eventos`)
            window.dataLayer.push({
              event: "GAEvent",
              event_category: "Content",
              event_action: "Explora lo que se viene",
              event_label: "Ver Lista",
              interaction: true,
              component_name: "boton_ver_lista",
              element_text: "Ver Lista",
            })
          }}
        >
          <img src={cubes} alt="lista" />
          <p>VER LISTA</p>
        </div>
      </div>
    </header>
  )
}

export default HeaderProvinciaMap
