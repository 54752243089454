import React, { useState } from "react"
import { navigate } from "gatsby"
import styles from "../../styles/pointer.module.scss"
import icon from "../../images/quilmesMusica/svg/quilmes-web-12.svg"
import iconEar from "../../images/quilmesMusica/svg/quilmes-web-14.svg"

const markerStyle = {
  position: "absolute",
  top: "100%",
  left: "50%",
  transform: "translate(-50%, -100%)",
}

export default function Pointer({ visiblePointer, provincia, lat, lng }) {
  const [visiblePdvInfo, setVisiblePdvInfo] = useState(false)

  const handleClick = () => {
    setVisiblePdvInfo(!visiblePdvInfo)
  }


  const handleClear = () => {
    window.dataLayer.push({
      event: "GAEvent",
      event_category: "Content",
      event_action: `${visiblePointer.provincia}`,
      event_label: `${visiblePointer.titulo}`,
      interaction: true,
      component_name: "boton_ver_mas",
      element_text: "Ver más",
    })
    setVisiblePdvInfo(!visiblePdvInfo)
    navigate("/quilmesfestivales/formulario", {
      state: { event: visiblePointer, provincia: provincia },
    })
  }
  // console.log(visiblePdvInfo, "pointer js")
  return (
    <div className={styles.container}>
      {/* <div
        // style={{ left: `${lng}%`, top: `${lat}%` }}
        className={styles.iconContainer}
        onClick={handleClick}
      >
      </div> */}
      {/* <img src={iconEar} alt="iconear" /> */}

      <div className={styles.infoContainer}>
        <div className={styles.topInfo}>
          <img className={styles.clearIcon} src={icon} onClick={handleClear} />
          {/* <h1>{visiblePointer.titulo}</h1> */}
          <div className={styles.bubble} onClick={handleClear}>
            <p className={styles.bubbleTitle}>{visiblePointer.titulo}</p>
            <p className={styles.bubbleSub}>VER MAS</p>
          </div>
        </div>
      </div>
    </div>
  )
}
