import React from "react"

import styles from "../../styles/provincias.module.scss"
import CityMap from "../../components/quilmes-musica/CityMap"
import HeaderProvinciaMap from "../../components/quilmes-musica/mapContainer/headerProvinciaMap"

const Provincias = ({ data, location: { search } }) => {
  let provinceName = search
    ? new URLSearchParams(search).get("prov")
    : "buenos_aires"

  const filter = provinceName
    .replace(/_/g, " ")
    .replace(/\b\w/g, l => l.toUpperCase())

  const test = data.allEventos.nodes.filter(evento => {
    const prov = evento.provincia
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
    return prov === filter
  })


  let province = provinceName ? provinceName.replace(/_/g, " ") : "Buenos Aires"
  province = province.replace(/\b\w/g, l => l.toUpperCase())

  return (
    <div className={styles.container}>
      <HeaderProvinciaMap title={province} text="Descubri su musica" />
      <div className={styles.mapContainer}>
        <CityMap province={provinceName} data={test} />
      </div>
    </div>
  )
}

export default Provincias

export const data = graphql`
  query Music2023Provincias {
    allEventos {
      nodes {
        id
        fecha
        imgBack
        latitud
        longitud
        comoLlegar
        localidad
        month
        provincia
        texto
        titulo
        active
      }
    }
  }
`
